import ABSService from "./absService";
import requestHelper from "../utils/requestHelper";

class NhanVienService extends ABSService {
    constructor() {
        super("nhanvien");
    }

    findByKeyword = async(keyword, page) => {
        const url = `/api/nhanvien?order=D_updatedAt&page=${page}&count=15&txt=${keyword}`;
        let data = await requestHelper.get(url, {});
        return data;
    }

    syncHRM = async() => {
        let data = await requestHelper.post('/api/nhanvien/hrm', {});
        return data;
    }

    syncData = async(importData) => {
        let data = await requestHelper.post('/api/nhanvien/sync', importData);
        return data;
    }

    searchDeep = async(name) => {
        let data = await requestHelper.post('/api/nhanvien/searchDeep', {name});
        return data;
    }

    searchDangNghi = async(name, offset, limit, trangThai = 1) => {
        const url = `/api/nhanvien/dsDangNghi`
        let data = await requestHelper.post(url, {name, offset, limit, trangThai});
        return data;
    }

    searchHetPhep = async(name, offset, limit) => {
        const url = `/api/nhanvien/layDsHetPhepChuaQL`
        let data = await requestHelper.post(url, {name, offset, limit});
        return data;
    }
    
    searchQuaHan = async(name, offset, limit) => {
        const url = `/api/nhanvien/layDsQuaHanHenLai/`
        let data = await requestHelper.post(url, {name, offset, limit});
        return data;
    }
    searchThoiViec = async(name, offset, limit) => {
        const url = `/api/nhanvien/layDsThoiViec/`
        let data = await requestHelper.post(url, {name, offset, limit});
        return data;
    }

    countDStTheoDoiNghiPhep = async() => {
        const url = `/api/nhanvien/countDenHan`
        let data = await requestHelper.post(url, {});
        return data;
    }
    
    getDsNhanSuMoi = async(query) => {
        const url = `/api/nhanvien/layDsNhanSuMoi`;
        let data = await requestHelper.post(url, query);
        return data;
    }
    getDsDangNghiPhep = async(query) => {
        const url = `/api/nhanvien/layDsDNP`;
        let data = await requestHelper.post(url, query);
        return data;
    }
    getDsNPQuayLai = async(query) => {
        const url = `/api/nhanvien/layDsNPQL`;
        let data = await requestHelper.post(url, query);
        return data;
    }
    getDsNPChuaQuayLai = async(query) => {
        const url = `/api/nhanvien/layDsNPCQL`;
        let data = await requestHelper.post(url, query);
        return data;
    }
    getDsNSDKNghiViec = async(query) => {
        const url = `/api/nhanvien/layDsNSDKNV`;
        let data = await requestHelper.post(url, query);
        return data;
    }
    getDsNSNghiViec = async(query) => {
        const url = `/api/nhanvien/layDsNSNV`;
        let data = await requestHelper.post(url, query);
        return data;
    }
    getNsHetPhepCQL = async(query) => {
        const url = `/api/nhanvien/layNsHpCQL`;
        let data = await requestHelper.post(url, query);
        return data;
    }
    getLyDoVePhepKQ = async(query) => {
        const url = `/api/nhanvien/lyDoVePhepKQ`;
        let data = await requestHelper.post(url, query);
        return data;
    }
    getLyDoVePhepCN = async(query) => {
        const url = `/api/nhanvien/lyDoVePhepCN`;
        let data = await requestHelper.post(url, query);
        return data;
    }
    getChamDutHDLD = async(query) => {
        const url = `/api/nhanvien/chamDutHDLD`;
        let data = await requestHelper.post(url, query);
        return data;
    }
    importBath = async(data) => {
        const url = "/api/nhanvien/import";
        let rs = await requestHelper.post(url, data);
        return rs;
    }
}

export default NhanVienService;