import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AddTask, BarChart, CalendarMonth, ImportExport, People, ReportProblem, Key, AccountTree } from '@mui/icons-material';
import PublishIcon from '@mui/icons-material/Publish';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import NhanVienPage from '../nhanvien/NhanVienPage';
import PhongBanPage from '../phongban/PhongBanPage';
import CuaKhauPage from '../cuakhau/CuaKhauPage';
import LichVePhepPage from '../lichvephep/LichVePhepPage';
import QLLaoDongPage from '../quanlylaodong/quanLyLaoDongPage';
import LyDoPage from '../lydo/LyDoPage';
import DanhSachHetPhepPage from '../danhsachhetphep/DanhSachHetPhepPage';
import ThongKePage from '../thongke.js/ThongKePage';
import ImportPage from '../nhapdulieu/ImportExcel';
import ImportNhanVien from '../nhapdulieu/ImportNhanVien';
import QLLaoDongService from '../../services/qlLaoDongService';
import NhanVienService from '../../services/nhanvienService';
import { PUBLIC_URL } from '../../utils/cons';
import { Badge, Grid } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

const qlLaoDongService = new QLLaoDongService();
const nhanVienService = new NhanVienService();
const publicUrl = PUBLIC_URL.replace('/', '');

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const menuItems = [

  {
    name: "Theo dõi nghỉ phép",
    Icon: <ReportProblem></ReportProblem>,
    page: <DanhSachHetPhepPage></DanhSachHetPhepPage>,
    path: publicUrl + '/nghi-phep'
  },
  {
    name: "Thông tin nhân sự",
    Icon: <People></People>,
    page: <NhanVienPage></NhanVienPage>,
    path: publicUrl + '/nhan-su'
  },
  {
    name: "Báo cáo VP KLH",
    Icon: <BarChart></BarChart>,
    path: 'thong-ke',
    page: <ThongKePage></ThongKePage>,
    path: publicUrl + '/thong-ke'
  },
  {
    name: "Import Nhân viên",
    Icon: <PublishIcon></PublishIcon>,
    page: <ImportNhanVien></ImportNhanVien>,
    path: publicUrl + '/importnv'
  },

  // {
  //   name: "Lịch về phép",
  //   Icon: <CalendarMonth></CalendarMonth>,
  //   page: <LichVePhepPage></LichVePhepPage>,
  //   path: publicUrl + '/lich-ve-phep'
  // },
  {
    name: "Phòng ban",
    Icon: <AccountTree></AccountTree>,
    page: <PhongBanPage></PhongBanPage>,
    path: publicUrl + '/phong-ban'
  },
  {
    name: "Cửa khẩu",
    Icon: <ImportExport></ImportExport>,
    page: <CuaKhauPage></CuaKhauPage>,
    path: publicUrl + '/cua-khau'
  },
  {
    name: "Lý do",
    Icon: <AddTask></AddTask>,
    page: <LyDoPage></LyDoPage>,
    path: publicUrl + '/ly-do'
  },
  {
    name: "Quản lý người dùng",
    Icon: <Key></Key>,
    page: <QLLaoDongPage></QLLaoDongPage>,
    path: publicUrl + '/quan-ly-lao-dong'
  },
  {
    name: "Import Excel",
    Icon: <PublishIcon></PublishIcon>,
    page: <ImportPage></ImportPage>,
    path: publicUrl + '/import'
  }
];

export default function MainPage(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [user, setUser] = React.useState({});
  const [menu, setMenu] = React.useState(menuItems);
  const [count, setCount] = React.useState(0);
  const handleDrawerOpen = () => {

    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [title, setTitle] = React.useState(menuItems[0].name, menuItems[0].Icon);

  const getTitle = (item) => {
    return (<span>{item.Icon} {item.name}</span>)
  }

  const getItemIcon = (item) => {
    let icon = item.Icon;
    if (item.name == menu[0].name) {
      icon = (<Badge badgeContent={count} color="error">
        {item.Icon}
      </Badge>);
    }
    return icon;
  }

  React.useEffect(() => {
    qlLaoDongService.getCurrentUser().then((us) => {
      if (!us.isAdmin) {
        const newMenu = menuItems.splice(0, 4);
        setMenu(newMenu);
      }
      setUser(us);
    });
    let i = 0;
    for (let menuItem of menuItems) {
      if (window.location.pathname.includes(menuItem.path)) {
        setSelectedIndex(i);
        setTitle(menuItem.Icon);
        setTitle(menuItem.name);
      }
      i++
    }
    nhanVienService.countDStTheoDoiNghiPhep().then((rs) => {
    setCount(rs.count);
    });
  }, [props.id]);

  return (
    <Router>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Grid container>
              <Grid item xs={8}>
                <h4>
                  {title}
                </h4>
              </Grid>
              <Grid item xs={4}>
                <h5>
                  <IconButton>
                    <AccountCircle>
                    </AccountCircle>
                  </IconButton>
                  {user.name}
                  <a className='logout' href='/'>
                    <LogoutIcon></LogoutIcon>
                  </a>
                </h5>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <h1 className="h2 text-light text-center push-top-bottom animation-slideDown">
              <img width="150px" src='https://workplace.thacoagri.com.vn/img/agriculture-logo.png' />
            </h1>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {
              menu.map((item, i) => (
                <ListItem key={item.path} disablePadding sx={{ display: 'block' }}>
                  <Link to={"/" + item.path} style={{ textDecoration: "none", color: "inherit" }}>
                    <ListItemButton selected={i == selectedIndex}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      onClick={() => { setSelectedIndex(i); setTitle(getTitle(item)) }}>
                      <ListItemIcon sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}>
                        {getItemIcon(item)}
                      </ListItemIcon>
                      <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0, color: "inherit" }} />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))
            }
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Routes>
            <Route path="/ql-vephep/" element={<Navigate to="/ql-vephep/nghi-phep" />} />
            {
              menu.map((item, i) => (
                <Route key={item.path + i} path={"/" + item.path} element={item.page} />
              ))
            }
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}